import React, { useState } from 'react';
import axios from 'axios';
import api from '../../../baseurl';
import css from '../style.module.css';

function SignUp(props) {
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password1, setPassword1] = useState("");

    const [errorText, setErrorText] = useState(null);

    function Submit() {
        setErrorText("")
        if (username && email && password && password1) {
            if (username.length !== 8) {
                setErrorText("Утасны дугаарыг зөв форматаар оруулна уу. жнь: 88998899")
            } else if (password === password1) {
                axios.post(`${api.baseURL}/signup`, {
                    username: username,
                    password: password,
                    email: email,
                    uuid: props.uuid,
                    type: props.type
                }).then((response) => {
                    if (response.data.message) {
                        setErrorText(response.data.message)
                    } else {
                        localStorage.setItem("loggedIn", true)
                        if (props.type === "paid") {
                            window.location.reload();
                            localStorage.removeItem("uuid")
                        } else {
                            window.location.href = "/dashboard";
                        }
                    }
                }).catch((error) => {
                    setErrorText("Системийн алдаа гарлаа. Та хэсэг хүлээгээд дахин оролдоно уу.")
                })
            } else {
                setErrorText("Нууц үгээ шалгана уу...")
            }
        } else {
            setErrorText("Талбаруудыг гүйцэд бөглөнө үү...")
        }
    }

    const message = {
        mn: {
            phone: "Утасны дугаар",
            email: "Мэйл хаяг",
            pass: "Нууц үг",
            repPass: "Нууц үг давтах",
            submit: "Бүртгүүлэх"
        },
        en: {
            phone: "Phone number",
            email: "E-mail",
            pass: "Password",
            repPass: "Repeat password",
            submit: "Submit"
        }
    }

    return (
        <div className={css.formContent} >
            <div>
                {errorText && (<article className="message is-warning"><div className="message-body">{errorText}</div></article>)}
                <div>
                    <label>{message[locale].phone}</label>
                    <input type="text" onChange={(e) => {
                        setUsername(e.target.value);
                    }} />
                </div>
                <div>
                    <label>{message[locale].email}</label>
                    <input type="email" onChange={(e) => {
                        setEmail(e.target.value);
                    }} />
                </div>
                <div>
                    <label>{message[locale].pass}</label>
                    <input type="password" onChange={(e) => {
                        setPassword(e.target.value);
                    }} />
                </div>
                <div>
                    <label>{message[locale].repPass}</label>
                    <input type="password" onChange={(e) => {
                        setPassword1(e.target.value);
                    }} />
                </div>
                <div>
                    <input type="submit" value={message[locale].submit} onClick={Submit} />
                </div>
            </div>
        </div >
    )
}

export default SignUp;