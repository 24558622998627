import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import api from '../../../baseurl'
import css from '../style.module.css'

function Login(props) {
    const navigate = useNavigate();
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [errorText, setErrorText] = useState(null);

    const loginSubmit = () => {
        if (username && password) {
            if (username.length !== 8) {
                setErrorText("Утасны дугаарыг дараах форматаар оруулна уу. жнь: 88998899")
            } else {
                axios.post(`${api.baseURL}/login`, {
                    username: username,
                    password: password,
                    type: props.type,
                    uuid: props.uuid
                }).then((response) => {
                    if (response.data.message) {
                        setErrorText(response.data.message)
                    } else {
                        localStorage.setItem("loggedIn", true)

                        if (props.type === "paid") {
                            window.location.reload();
                        } else {
                            window.location.href = "/dashboard";
                        }
                    }
                }).catch((error) => {
                    setErrorText("Системийн алдаа. Та түр хүлээгээд дахин оролдоно уу.")
                })
            }
        } else {
            setErrorText("Талбаруудыг гүйцэд бөглөнө үү...")
        }
    }

    const message = {
        mn: {
            phone: "Утасны дугаар",
            pass: "Нууц үг",
            lostPass: "Нууц үг мартсан",
            submit: "Нэвтрэх"
        },
        en: {
            phone: "Phone number",
            pass: "Password",
            lostPass: "Forget password",
            submit: "Submit"
        }
    }

    return (
        <div className={css.formContent}>
            <div>
                {errorText && (<article className="message is-warning"><div className="message-body">{errorText}</div></article>)}
                <div>
                    <label>{message[locale].phone}</label>
                    <input type="text" id="login" maxLength={8} minLength={8} onChange={(e) => {
                        setUsername(e.target.value);
                    }} />
                </div>
                <div>
                    <label>{message[locale].pass}</label>
                    <input type="password" id="password" onChange={(e) => {
                        setPassword(e.target.value);
                    }} />
                </div>
                <span onClick={() => navigate("/forgot-password")}>{message[locale].lostPass}</span>
                <div>
                    <input type="submit" value={message[locale].submit} onClick={loginSubmit} />
                </div>
            </div>
        </div>
    )
}

export default Login;