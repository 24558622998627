import React, { useState } from 'react';
import css from '../../style.module.css';
import axios from 'axios';
import api from '../../../../baseurl';

function ChangePassword() {
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";
    const [oldPass, setOldPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [newPassDup, setNewPassDup] = useState("")
    const [warningText, setWarningText] = useState("")
    const message = {
        mn: {
            title: "Нууц үг солих",
            oldPass: "Хуучин нууц үг:",
            newPass: "Шинэ нууц үг:",
            repPass: "Нууц үг давтах:",
            submit: "Нууц үг солих"
        },
        en: {
            title: "Change Password",
            oldPass: "Old password:",
            newPass: "New password:",
            repPass: "Repeat password:",
            submit: "Submit"
        }
    }

    function changePassword() {
        setWarningText("")
        if (oldPass && newPass && newPassDup) {
            if (newPass === newPassDup) {
                axios.post(`${api.baseURL}/change-password`, {
                    oldPass: oldPass,
                    newPass: newPass
                }).then((response) => {
                    if (response.data.message) {
                        setWarningText(response.data.message)
                    }
                }).catch((error) => {
                    setWarningText(error.message)
                })
            } else {
                setWarningText("Шинэ нууц үгээ шалгаад дахин оролдоно уу...")
            }
        } else {
            setWarningText("Бүх талбарыг гүйцэд бөглөнө үү...")
        }
    }

    return (
        <div className={css.formContent}>
            <h3>{message[locale].title}</h3>
            <div>
                {warningText && (<article className="message is-warning"><div className="message-body">{warningText}</div></article>)}
                <div>
                    <label>{message[locale].oldPass}</label>
                    <input type="password" onChange={(e) => { setOldPass(e.target.value) }} />
                </div>
                <div>
                    <label>{message[locale].newPass}</label>
                    <input type="password" onChange={(e) => { setNewPass(e.target.value) }} />
                </div>
                <div>
                    <label>{message[locale].repPass}</label>
                    <input type="password" onChange={(e) => { setNewPassDup(e.target.value) }} />
                </div>
                <div>
                    <input type="submit" value={message[locale].submit} onClick={changePassword} />
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;