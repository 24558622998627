import React, { useState, useEffect } from 'react';
import Login from './login';
import SignUp from './signup';
import axios from 'axios';
import css from './style.module.css'
import api from '../../baseurl'
import SyncLoader from "react-spinners/SyncLoader";

function LoginAndSignUp(props) {
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";
    const [selectedTab, setSelectedTab] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [loading, setLoading] = useState(true)

    const message = {
        mn: {
            login: "НЭВТРЭХ",
            signup: "БҮРТГҮҮЛЭХ"
        },
        en: {
            login: "LOGIN",
            signup: "SIGN UP"
        }
    }

    useEffect(() => {
        setLoading(true);
        axios.get(`${api.baseURL}/check-user-login`).then((response) => {
            if (response.data.loggedIn) {
                localStorage.setItem("loggedIn", true)
                window.location.href = "/dashboard";
            } else {
                localStorage.removeItem("loggedIn")
                setLoading(false)
            }
        }).catch((error) => {
            setErrorText(error.message)
            setLoading(false);
        })
    }, [])

    return (
        loading ? <SyncLoader loading={loading} size={12} /> :
            <div className={css.tabContain}>
                {errorText && (<article className="message is-warning"><div className="message-body">{errorText}</div></article>)}
                <div className={css.tabs}>
                    <div className={selectedTab ? css.active : ""} onClick={() => { setSelectedTab(true) }}>{message[locale].login}</div>
                    <div className={selectedTab ? "" : css.active} onClick={() => { setSelectedTab(false) }}>{message[locale].signup}</div>
                </div>
                <div className={css.tabContent}>
                    {selectedTab ? <Login type={props.type} uuid={props.uuid} /> : <SignUp type={props.type} uuid={props.uuid} />}
                </div>
            </div>
    )
}

export default LoginAndSignUp;