import React from 'react';
import css from '../../style.module.css'
import api from '../../../../baseurl.js'
import { Link } from "react-router-dom";

function UserInfo(props) {
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";
    const message = {
        mn: {
            title: "Таны мэдээлэл",
            phone: "Утасны дугаар:",
            email: "Имэйл хаяг:",
        },
        en: {
            title: "Your information",
            phone: "Phone number",
            email: "E-mail",
        }
    }

    return (
        <div className={css.formContent}>
            <h3>{message[locale].title}</h3>
            <div>
                <div>
                    <label>{message[locale].phone}</label>
                    <input type="text" value={props.user.phone} disabled />
                </div>
                <div>
                    <label>{message[locale].email}</label>
                    <input type="text" value={props.user.email} disabled />
                </div>
                <div>
                    <label>Арлын дугаар харах нийт эрх</label>
                    <input type="text" value={props.user.count} disabled />
                </div>
                <div>
                    <h3>Таны үзсэн арлын дугаарууд {props.values.length && props.values.length}</h3>
                    {
                        props.values.length && props.values.map((val, index) => {
                            return (
                                <div key={index}>
                                    <Link to={`/stats/auction/${val.carId}/${val.chassis}-${val.modelNumber}`} className="link">
                                        <img style={{ border: '1px solid black', marginBottom: '5px' }} width={250} height={50} alt='' src={`${api.imgURL}/${val.path}/${val.crop_auction_id}`} />
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default UserInfo;