import React from "react";
import Shadow from "../shadow";
import css from "./style.module.css";

const Popup = props => {
    return props.show ?
        <>
            <Shadow show={props.show} setShow={props.setShow} />
            <div className={css.Popup}>
                <button className={css.button} onClick={() => { props.setShow("") }}>x</button>
                <div className={css.text}>{props.children}</div>
            </div>
        </> : null;
};

export default Popup;