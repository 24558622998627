import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import api from '../../../baseurl'
import css from '../style.module.css'

function ResetPassword() {
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";
    const [errorText, setErrorText] = useState(null);

    const [pass, setPass] = useState("");
    const [pass1, setPass1] = useState("");

    let { token } = useParams();

    const submit = () => {
        if (pass === pass1 && pass) {
            axios.post(`${api.baseURL}/reset-password`, {
                password: pass,
                token: token
            }).then((response) => {
                if (response.data.message) {
                    setErrorText(response.data.message)
                    if (response.data.message === "Нууц амжилттай шинэчлэгдлээ") {
                        setTimeout(() => {
                            window.location.href = "/dashboard";
                        }, 2000);
                    }
                }
            }).catch((error) => {
                setErrorText(error.message)
            })
        } else {
            setErrorText("Нууц үгээ шалгаад дахин оруулна уу.")
        }
    }

    const message = {
        mn: {
            pass: "Шинэ нууц үг",
            repPass: "Нууц үг давтах",
            submit: "Нууц үг шинэчлэх"
        },
        en: {
            pass: "New password",
            repPass: "Repeat password",
            submit: "Submit"
        }
    }

    return (
        <div className={css.formContent}>
            <div>
                {errorText && (<article className="message is-warning"><div className="message-body">{errorText}</div></article>)}
                <div>
                    <label>{message[locale].pass}</label>
                    <input type="email" name="login" onChange={(e) => {
                        setPass(e.target.value);
                    }} />
                </div>
                <div>
                    <label>{message[locale].repPass}</label>
                    <input type="email" name="login" onChange={(e) => {
                        setPass1(e.target.value);
                    }} />
                </div>
                <div>
                    <input type="submit" name="submit" value={message[locale].submit} onClick={submit} />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;