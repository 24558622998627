import React, { useState } from 'react';
import api from '../../baseurl'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Clients from './clients';
import SearchAuctionEmpty from './emptyResult';
import PaymentList from './payments';
import CouponCode from './couponCode';
import ReportHistory from './reportHistory';

import css from './style.module.css'

function AdminPanel() {
    const navigate = useNavigate();

    const [client, setClient] = useState(true);
    const [emptyAuction, setEmptyAuction] = useState(false);
    const [payment, setPayment] = useState(false);
    const [couponCode, setCouponCode] = useState(false);
    const [reportHistory, setReportHistory] = useState(false);

    const [errorText, setErrorText] = useState("");

    function logout() {
        axios.get(`${api.baseURL}/v1/admin/login-out`).then((response) => {
            localStorage.removeItem("token");
            navigate("/system/admin");
        }).catch((error) => {
            setErrorText(error.message);
        })
    }

    return (
        <>
            <h3>СИСТЕМИЙН АДМИН ПАНЕЛ</h3>
            <span className={css.logout} onClick={logout}>гарах</span>
            <div className={css.adminTabs}>
                <div className={client ? css.active : ''} onClick={() => { setReportHistory(false); setClient(true); setEmptyAuction(false); setPayment(false); setCouponCode(false) }}>ХАНДСАН ХЭРЭГЛЭГЧИД</div>
                <div className={payment ? css.active : ''} onClick={() => { setReportHistory(false); setClient(false); setEmptyAuction(false); setPayment(true); setCouponCode(false) }}>QPAY ТӨЛБӨР ТӨЛӨЛТ</div>
                <div className={reportHistory ? css.active : ''} onClick={() => { setReportHistory(true); setClient(false); setEmptyAuction(false); setPayment(false); setCouponCode(false) }}>REPORT HISTORY</div>
                <div className={emptyAuction ? css.active : ''} onClick={() => { setReportHistory(false); setClient(false); setEmptyAuction(true); setPayment(false); setCouponCode(false) }}>ҮР ДҮН ОЛДООГҮЙ AUCTION</div>
                <div className={couponCode ? css.active : ''} onClick={() => { setReportHistory(false); setClient(false); setEmptyAuction(false); setPayment(false); setCouponCode(true) }}>КОД АШИГЛАСАН ҮР ДҮН</div>
            </div>
            <div style={{ padding: "10px", background: "#ffc107" }}>{errorText}</div>
            {client && <Clients />}
            {emptyAuction && <SearchAuctionEmpty />}
            {payment && <PaymentList />}
            {couponCode && <CouponCode />}
            {reportHistory && <ReportHistory />}
        </>
    );
}

export default AdminPanel;
