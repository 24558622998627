import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from 'react-router-dom';

import css from './style.module.css'
import api from '../../../baseurl';
import Popup from '../../../components/popup';
import EditForm from '../editForm';
import authHeader from '../../../components/setAuthToken';

function Clients() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [clients, setClients] = useState("");
    const [errorText, setErrorText] = useState("");

    const [id, setId] = useState("");
    const [phone, setPhone] = useState("");

    const [selectedId, setSelectedId] = useState("");

    useEffect(() => {
        setErrorText("");
        setClients("");

        axios.post(`${api.baseURL}/v1/admin/search-clients`, {
            id: id,
            phone: phone,
        }, {
            headers: authHeader()
        }).then((response) => {
            if (response.data.auth === false) {
                navigate("/");
            }

            if (response.data.status === false) {
                setErrorText(response.data.message);
            } else {
                setClients(response.data);
            }
            setLoading(false)
        }).catch((error) => {
            setErrorText(error.message);
            setLoading(false)
        })
    }, [id, phone, navigate])

    return (
        loading ? <SyncLoader /> :
            <div>
                <Popup show={selectedId ? true : false} setShow={setSelectedId} >
                    <EditForm id={selectedId} />
                </Popup>
                <div className={css.users}>
                    {errorText && <span >{errorText}</span>}
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>ID</th>
                                <th>Phone</th>
                                <th>Count</th>
                                <th>Auctions</th>
                                <th>Email</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td><input type='text' name="id" placeholder='id оруулах' onKeyPress={(e) => { e.key === 'Enter' && setId(e.target.value) }} /></td>
                                <td><input type='text' name="phone" placeholder='утасны дугаар' onChange={(e) => { setPhone(e.target.value) }} /></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {
                                clients.length ?
                                    clients.map((val, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{val.id}</td>
                                                <td>{val.phone}</td>
                                                <td>{val.count}</td>
                                                <td>{val.searchValues}</td>
                                                <td>{val.email}</td>
                                                <td><button className={css.edit} onClick={() => { setSelectedId(val.id) }}>засах</button></td>
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={6}><h3>Хэрэглэгч олдсонгүй</h3></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
    );
}

export default Clients;
