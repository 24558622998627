import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
import api from '../../../baseurl';
import css from './style.module.css';
import authHeader from '../../../components/setAuthToken';

function LoginAdmin() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [errorText, setErrorText] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios.get(`${api.baseURL}/v1/admin/login-check`, {
            headers: authHeader()
        }).then((response) => {
            if (response.data.loggedIn) {
                navigate('/ec9ae912/panel');
            }
            setLoading(false);
        }).catch((error) => {
            setErrorText(error.message)
            setLoading(false);
        })
    }, [navigate])

    const loginSubmit = () => {
        setLoading(true);
        axios.post(`${api.baseURL}/v1/admin/login`, {
            username: username,
            password: password,
        }).then((response) => {
            if (response.data.status === true) {
                localStorage.setItem("token", response.data.token)
                setLoading(false)
                navigate('/ec9ae912/panel');
            } else {
                setErrorText(response.data.message)
                setLoading(false);
            }
        }).catch((error) => {
            setErrorText(`Сервер унтарсан байна:`)
            setLoading(false);
        })
    }

    return (

        loading ? <SyncLoader loading={loading} size={12} /> :
            <div className={css.formContent}>
                <form>
                    {errorText && (<article className="message is-warning"><div className="message-body">{errorText}</div></article>)}
                    <div>
                        <input type="text" maxLength={8} minLength={8} placeholder="Нэвтрэх нэр" onChange={(e) => {
                            setUsername(e.target.value);
                        }} />
                    </div>
                    <div>
                        <input type="password" placeholder="Нууц үг" onChange={(e) => {
                            setPassword(e.target.value);
                        }} />
                    </div>
                    <div>
                        <input type="submit" value="Нэвтрэх" onClick={loginSubmit} />
                    </div>
                </form>
            </div>
    )
}

export default LoginAdmin;