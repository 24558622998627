import { useEffect } from 'react';

function CheckPaymentAuto(props) {

    useEffect(() => {
        let interval

        interval = setInterval(() => {
            props.checkPayment()
        }, 120 * 1000)
        return () => {
            clearInterval(interval)
        }
    })
}

export default CheckPaymentAuto;
