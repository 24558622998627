import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import SyncLoader from "react-spinners/SyncLoader";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll'

import css from './style.module.css'
import api from '../../baseurl';

function CarResults(props) {
    const params = useParams();
    const [loading, setLoading] = useState(true)
    const [resultAuction, setResultAuction] = useState([]);
    const [errorText, setErrorText] = useState("");
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";

    const message = {
        mn: {
            text: "Та доорх зурагнуудаас зөв арлын дугаартай зураг дээр дарж дэлгэрэнгүй мэдээллийг харна уу.",
            error: "Системийн алдаа хэсэг хугацааны дараа дахин хайна уу.",
        },
        en: {
            text: "Please click on the image with the correct island number from the images below to see more information.",
            error: "System error. Please try again after a while.",
        }
    }

    useEffect(() => {
        setLoading(true)
        scroll.scrollTo(100)
        setErrorText("");
        props.setSearchValue(params.modelNumber)
        fetch('https://api.ipify.org/?format=json').then(results => results.json()).then((data) => {
            setResultAuction([])
            axios.post(`${api.baseURL}/v1/find/${params.modelNumber}`, {
                modelNumber: params.modelNumber,
                ip: data.ip,
            }).then((response) => {
                if (response.data.status === true) {
                    setResultAuction(response.data.data);
                } else {
                    setErrorText(locale === 'mn' ? response.data.message : response.data.messageEn);
                }
                scroll.scrollTo(200)
                setLoading(false)
            }).catch((error) => {
                setErrorText(message[locale].error);
                setLoading(false)
            })
        })

    }, [params.modelNumber])

    return (
        loading ? <SyncLoader /> :
            <div className={css.auctions}>
                {errorText && <span className={css.errorText}>{errorText}</span>}
                {
                    resultAuction.length ?
                        <>
                            <span className={css.text}>{message[locale].text}</span>
                            {
                                resultAuction.map((val, index) => {
                                    return (
                                        <div key={index} className={css.auction}>
                                            <h2>{index + 1}.</h2>
                                            <Link to={`/stats/auction/${val.carId}/${params.modelNumber}/${val.type}`} target="_blank" className="link" >
                                                <img width={250} height={50} src={`${val.image}`} alt='' />
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </>
                        : <h3>Тохирох үр дүн олдсонгүй</h3>
                }
            </div>
    );
}

export default CarResults;
