import React from 'react';
import SyncLoader from "react-spinners/SyncLoader";
import css from './style.module.css'

function Qpay(props) {

    return (
        <div className={css.qpayContain}>
            <img src={`data:image/png;base64,${props.data.qr_image}`} alt={''} />
            {props.loading ? <SyncLoader loading={props.loading} size={8} /> : props.errorText && <span className={css.errorText}>{props.errorText}</span>}
            <button onClick={() => { props.checkPayment() }}>Төлбөр шилжүүлсэн</button>
            <div>
                <span>Банк сонгох</span>
            </div>
            <div>
                {
                    props.data.urls.map((val, index) => {
                        return (
                            val.description !== 'Тээвэр хөгжлийн банк' &&
                            <a key={index} className='' href={val.link}>
                                <img alt={val.description} src={val.logo} />
                                <span>{val.description}</span>
                            </a>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Qpay;