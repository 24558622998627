import React from 'react';
import css from './style.module.css';

function AuctionDescTable() {

    return (
        <div className={css.tables}>
            <h3 style={{ width: '100%' }}>Дуудлага худалдааны хуудасны үнэлгээний тайлбар</h3>
            <div className={css.tablesCol}>
                <table className={css.auction}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Гадна арьсны сэв</th>
                        </tr>
                        <tr>
                            <th>Үнэлгээ</th>
                            <th>Тайлбар</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A1</td><td>Нүдэнд бараг харагдахгүй жижиг зураас</td>
                        </tr>
                        <tr>
                            <td>A2</td><td>Бага зэргийн зураас</td>
                        </tr>
                        <tr>
                            <td>A3</td><td>Том зураас</td>
                        </tr>
                        <tr>
                            <td>U(B)1</td><td>Нүдэнд бараг харагдахгүй жижиг хонхорхой</td>
                        </tr>
                        <tr>
                            <td>U(B)2</td><td>Бага зэргийн хонхорхой</td>
                        </tr>
                        <tr>
                            <td>U(B)3</td><td>Том хонхорхой</td>
                        </tr>
                        <tr>
                            <td>AU(AB)1</td><td>Жижиг зураастай хонхорхой</td>
                        </tr>
                        <tr>
                            <td>AU(AB)2</td><td>Дунд зэргийн зураастай хонхорхой</td>
                        </tr>
                        <tr>
                            <td>AU(AB)3</td><td>Том зураастай хонхорхой</td>
                        </tr>
                        <tr>
                            <td>W1</td><td>Нүдэнд бараг харагдахгүй бага зэрэг засаж будсан</td>
                        </tr>
                        <tr>
                            <td>W2</td><td>Бага зэрэг засаж будсан</td>
                        </tr>
                        <tr>
                            <td>W3</td><td>Их хэмжээний засаж будсан</td>
                        </tr>
                        <tr>
                            <td>P1</td><td>Халцарсан толбо, жижиг</td>
                        </tr>
                        <tr>
                            <td>P2</td><td>Халцарсан толбо, дунд</td>
                        </tr>
                        <tr>
                            <td>P3</td><td>Халцарсан толбо, том</td>
                        </tr>
                        <tr>
                            <td>X</td><td>Солих хэрэгтэй</td>
                        </tr>
                        <tr>
                            <td>XX</td><td>Сольсон</td>
                        </tr>
                        <tr>
                            <td>S</td><td>Зэвтэй</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className={css.tablesCol}>
                <table className={css.auction}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Машины ерөнхий үнэлгээ</th>
                        </tr>
                        <tr>
                            <th>Үнэлгээ</th>
                            <th>Тайлбар</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>S</td><td>Шинэ машин</td>
                        </tr>
                        <tr>
                            <td>5</td><td>Шинэвтэр</td>
                        </tr>
                        <tr>
                            <td>4,5</td><td>Маш сайн машин. Ямар нэг зураас, хонхорхой байхгүй</td>
                        </tr>
                        <tr>
                            <td>4</td><td>Сайн машин. Бага зэрэг сэв зураастай</td>
                        </tr>
                        <tr>
                            <td>3,5</td><td>Зураас, сэвтэй машин. Гэхдээ хэвийн</td>
                        </tr>
                        <tr>
                            <td>3</td><td>Будаг нь халцарсан, том сэвтэй</td>
                        </tr>
                        <tr>
                            <td>RA</td><td>Багахан осолд орсон, стандартын дагуу засварт орсон</td>
                        </tr>
                        <tr>
                            <td>R/A/0</td><td>Осолд орсон, зарим хэсгийг сольсон эсвэл зассан</td>
                        </tr>
                        <tr>
                            <td>***</td><td>Осолд орж эвдэрсэн, засварлаагүй. Хөдөлгүүр гэмтсэн</td>
                        </tr>
                    </tbody>
                </table>

                <table style={{ marginTop: '10px' }} className={css.auction}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Салоны үнэлгээ</th>
                        </tr>
                        <tr>
                            <th>Үнэлгээ</th>
                            <th>Тайлбар</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A</td><td>Салон шинэ</td>
                        </tr>
                        <tr>
                            <td>B</td><td>Салон цэвэрхэн. Маш ховор</td>
                        </tr>
                        <tr>
                            <td>C</td><td>Салоныг цэвэрлэх шаардлагатай. Гэмтэлгүй</td>
                        </tr>
                        <tr>
                            <td>D</td><td>Салоныг цэвэрлэх шаардлагатай. Бага зэргийн гэмтэлтэй</td>
                        </tr>
                        <tr>
                            <td>E</td><td>Салон муу, гэмтэлтэй. Засах шаардлагатай</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AuctionDescTable;
