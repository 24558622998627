import React, { useState, useEffect } from 'react';
import css from './style.module.css'
import picture1 from '../../../../images/picture1.png'
import goyCar from '../../../../images/goy-car.png'
import repairCar from '../../../../images/repair-befor-after.jpg'
import auctionTailbar from '../../../../images/auction-tailbar-jishee.png'

function Blog2() {

    return (
        <div className={css.blog}>
            <div className={css.blogTitle}>
                <h2>Сайн машин авч байгаагаа яаж мэдэх вэ?</h2>
            </div>
            <div className={css.blogContent}>
                <p>Та зөвхөн тээврийн хэрэгслийн арлын дугаарыг мэддэг байхад л болно!.</p>

                <div className={css.twoCol}>
                    <div className={css.imgs}>
                        <img src={goyCar} alt="" />
                    </div>
                    <p>
                        Гадаадаас орж ирж буй тээврийн хэрэгслүүд нь ихэвчлэн гадааддаа дуудлага худалдаагаар дамжин зарагддаг(Тэр дундаа японд цоо шинэ машин биш л бол заавал дуудлага худалгаагаар худалдаалагддаг).
                        Дуудлага худалдаанд орохоос өмнө баталгаажсан оношилгоонд ордог ба тус тээврийн хэрэгслийн үнэлгээ буюу оношилгооны үр дүнг бидний мэдэхээр <strong>үнэлгээ буюу аукцион(auction) хуудас</strong> гэдэг.
                    </p>
                </div>

                <p>
                    &emsp;<strong>Та Монголд орж ирж буй машин авахаар төлөвлөж байгаа бол</strong> тухайн машины оношилгооны үр дүн буюу аукционы үнэлгээг харах хамгийн чухал юм.
                    Тухайн үнэлгээний хуудсанд машины талаар сэв зурааснаас эхлээд эвдрэл гэмтэл, сольсон эд ангийг хүртэл маш сайн тодорхойлж дүгнэсэн байдаг.
                    Иймд та машиныхаа талаар хамгийн үнэн бодит мэдээллийг шалгаад сонголтоо хийх нь сэтгэл ханамжаас гадна амь насны баталгаа болно.
                    Мөн үнэлгээний хуудсанд тээврийн хэрэгслийн арлын дугаар байдаг учир зөв хуудас үзэж байгаа эсэхээ нягтлаарай.
                </p>

                <p>
                    <strong>Үнэлгээний хуудсыг харах өөр нэг чухал шалтгаан бол</strong> засаж янзалсан машиныг шинэ, сайн машин гэж итгэж авахаас сэргийлэх юм.
                    Монголд гэлтгүй гадаадын улс орнуудад энэ явдал тохиолддог ба үүнийг олон улсад гэмт хэрэг гэж үздэг. Засаж янзалсан гэдэгт дараах дараах асуудлууд орно:
                </p>
                <ul>
                    <li>Тээврийн хэрэгслийн гүйлт буюу үйлдвэрлэгдсэнээс хойш явсан км-г ухраах</li>
                    <li>Ослын болон бусад сэв зураасыг дарах, дахин будганд оруулах</li>
                    <li>Тээврийн хэрэгслийн эд ангийг солих, дутаах</li>
                    <li>Дотоод эвдрэл гэмтлийг нуун дарагдуулах гэх мэт олон асуудлууд багтана.</li>
                </ul>
                <p>Дээрх асуудлуудаас үүдэж эд хөрөнгө, мөнгө санхүүгээрээ хохироос гадна амь насанд хүртэл чухал асуудал юм.</p>

                <div className={css.imgs}>
                    <img src={repairCar} alt="" />
                </div>

                <h3>Аuction хуудсаа уншиж сурцгаая!!!</h3>
                <p>Японоос орж ирсэн тээврийн хэрэгслүүдийн Auction хуудас доорх үндсэн загвартай байдаг:</p>

                <div className={css.imgs}>
                    <img src={picture1} alt="" />
                </div>
                <p>Дараах 3 хүснэгтэд үнэлгээний тэмдэг тавигддаг талбаруудын тэмдэглэгээнүүдийн тайлбарыг оруулав.</p>

                <p><strong>Хүснэгт №1</strong>-т машины ерөнхий үнэлгээ байх ба нийт 9 үнэлгээний тэмдэглэгээний аль нэг нь байх болно. </p>
                <table className={css.auction}>
                    <thead>
                        <tr>
                            <th colSpan={2}><strong>Хүснэгт №1: </strong>Машины ерөнхий үнэлгээ</th>
                        </tr>
                        <tr>
                            <th>Үнэлгээ</th>
                            <th>Тайлбар</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>S</td><td>Шинэ машин</td>
                        </tr>
                        <tr>
                            <td>5</td><td>Шинэвтэр</td>
                        </tr>
                        <tr>
                            <td>4,5</td><td>Маш сайн машин. Ямар нэг зураас, хонхорхой байхгүй</td>
                        </tr>
                        <tr>
                            <td>4</td><td>Сайн машин. Бага зэрэг сэв зураастай</td>
                        </tr>
                        <tr>
                            <td>3,5</td><td>Зураас, сэвтэй машин. Гэхдээ хэвийн</td>
                        </tr>
                        <tr>
                            <td>3</td><td>Будаг нь халцарсан, том сэвтэй</td>
                        </tr>
                        <tr>
                            <td>RA</td><td>Багахан осолд орсон, стандартын дагуу засварт орсон</td>
                        </tr>
                        <tr>
                            <td>R/A/0</td><td>Осолд орсон, зарим хэсгийг сольсон эсвэл зассан</td>
                        </tr>
                        <tr>
                            <td>***</td><td>Осолд орж эвдэрсэн, засварлаагүй. Хөдөлгүүр гэмтсэн</td>
                        </tr>
                    </tbody>
                </table>
                <p><strong>Хүснэгт №2</strong>-т машины дотоод салоны үнэлгээ байх ба нийт 5 үнэлгээний тэмдэглэгээ байх болно.</p>
                <table className={css.auction}>
                    <thead>
                        <tr>
                            <th colSpan={2}><strong>Хүснэгт №2: </strong>Салоны үнэлгээ</th>
                        </tr>
                        <tr>
                            <th>Үнэлгээ</th>
                            <th>Тайлбар</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A</td><td>Салон шинэ</td>
                        </tr>
                        <tr>
                            <td>B</td><td>Салон цэвэрхэн. Маш ховор</td>
                        </tr>
                        <tr>
                            <td>C</td><td>Салоныг цэвэрлэх шаардлагатай. Гэмтэлгүй</td>
                        </tr>
                        <tr>
                            <td>D</td><td>Салоныг цэвэрлэх шаардлагатай. Бага зэргийн гэмтэлтэй</td>
                        </tr>
                        <tr>
                            <td>E</td><td>Салон муу, гэмтэлтэй. Засах шаардлагатай</td>
                        </tr>
                    </tbody>
                </table>

                <p><strong>Хүснэгт №3</strong>-т машины гадаад арьсны үнэлгээ байх ба олон үнэлгээний тэмдэглэгээ нь гадаад диталь бүрт харгалзаж харагдах болно.</p>
                <table className={css.auction}>
                    <thead>
                        <tr>
                            <th colSpan={2}><strong>Хүснэгт №3: </strong>Гадна арьсны сэв</th>
                        </tr>
                        <tr>
                            <th>Үнэлгээ</th>
                            <th>Тайлбар</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A1</td><td>Нүдэнд бараг харагдахгүй жижиг зураас</td>
                        </tr>
                        <tr>
                            <td>A2</td><td>Бага зэргийн зураас</td>
                        </tr>
                        <tr>
                            <td>A3</td><td>Том зураас</td>
                        </tr>
                        <tr>
                            <td>U(B)1</td><td>Нүдэнд бараг харагдахгүй жижиг хонхорхой</td>
                        </tr>
                        <tr>
                            <td>U(B)2</td><td>Бага зэргийн хонхорхой</td>
                        </tr>
                        <tr>
                            <td>U(B)3</td><td>Том хонхорхой</td>
                        </tr>
                        <tr>
                            <td>AU(AB)1</td><td>Жижиг зураастай хонхорхой</td>
                        </tr>
                        <tr>
                            <td>AU(AB)2</td><td>Дунд зэргийн зураастай хонхорхой</td>
                        </tr>
                        <tr>
                            <td>AU(AB)3</td><td>Том зураастай хонхорхой</td>
                        </tr>
                        <tr>
                            <td>W1</td><td>Нүдэнд бараг харагдахгүй бага зэрэг засаж будсан</td>
                        </tr>
                        <tr>
                            <td>W2</td><td>Бага зэрэг засаж будсан</td>
                        </tr>
                        <tr>
                            <td>W3</td><td>Их хэмжээний засаж будсан</td>
                        </tr>
                        <tr>
                            <td>P1</td><td>Халцарсан толбо, жижиг</td>
                        </tr>
                        <tr>
                            <td>P2</td><td>Халцарсан толбо, дунд</td>
                        </tr>
                        <tr>
                            <td>P3</td><td>Халцарсан толбо, том</td>
                        </tr>
                        <tr>
                            <td>X</td><td>Солих хэрэгтэй</td>
                        </tr>
                        <tr>
                            <td>XX</td><td>Сольсон</td>
                        </tr>
                        <tr>
                            <td>S</td><td>Зэвтэй</td>
                        </tr>
                    </tbody>
                </table>
                <h3>Жишээлбэл дараах байдлаар Auction хуудсаа уншиж болно</h3>
                <p>Дараах Auction хуудас дээрх тээврийн хэрэгслийн оношилгооны үнэлгээ, дүгнэлтийг тайлбарлая. </p>
                <div className={css.imgs}>
                    <img src={auctionTailbar} alt="" />
                </div>


                <p>Дээрх тайлбараас үзвэл энэхүү PRIUS 41 маркийн машины Auction хуудсанд:</p>
                <ul>
                    <li>
                        <strong>Гадна арьсны үнэлгээ.</strong> Баруун доод хэсэгт машины гадна арьсыг деталь бүрээр нь салгаж үнэлгээ тэмдэглэсэн байх ба тус тэмдэглээнүүдээс харахад 3 хаалга,
                        зүүн урд гүүпр үл мэдэгдэм жижиг хонхорхой, сэвтэй мөн салхины шил бага зэргийн зураастай ч бусад хэсгүүд асуудалгүй байна.
                    </li>
                    <li>
                        <strong>Бусад асуудал.</strong> Гадна арьсны үнэлгээний зүүн талд бусад дэлгэрэнгүй асуудлуудыг бичих хэсэг нь машины үйл ажиллагаа, явах эд ангийн хувьд маш чухал дүгнэлт байдаг.
                        Дээрх жишээнээс бичигдсэн асуудлуудыг дэлгэрэнгүй харна уу.
                    </li>
                    <li>
                        <strong>Нэмэлт тоноглол, мэдээлэл.</strong> Баруун дээд хэсэгт үнэлгээнүүдийн доор байрлах ба дутуу зүйл болон нэмэлт тоноглолын талаар бичигддэг.
                        Энэ машины хувьд LED гэрэлтэй, хурд баригчтай буюу Criuse control -той ажээ.
                    </li>
                    <li>
                        <strong>Салоны үнэлгээ.</strong> Баруун дээд булан ерөнхий үнэлгээний яг доор нь салоны үнэлгээ дээр <strong>“С”</strong> үсэг байгаа нь <strong>“Салоныг цэвэрлэх шаардлагатай ч гэмтэлгүй”</strong> гэсэн дүгнэлт юм.
                    </li>
                    <li>
                        <strong>Ерөнхий үнэлгээ.</strong> Дээрх бүх үзүүлэлт үнэлгээнүүдийг нэгтгэн дүгнэсний эцэст ерөнхий үнэлгээг баруун дээд буланд <strong>“3.5”</strong> гэж дүгнэсэн байна. Энэ нь дундаж машины үнэлгээ болно.
                    </li>
                </ul>
                <p>Гэх мэтчилэн Auction хуудсуудыг уншиж ойлгож болох юм. Бусад тайлбарлаагүй мэдээллүүд ч гэсэн танд чухал байж болох юм. Жишээлбэл: 1800сс мотортой, 070 гэсэн өнгөний кодтой машин ажээ.</p>
                <p>Эцэст нь та машиныхаа бүх үнэлгээ болон асуудлуудыг мэдсэнээр зөв шийдвэр гаргана гэж бид итгэж байна. Танд амжилт хүсье!.</p>
            </div>
        </div>
    )
}

export default Blog2;