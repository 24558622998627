
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Editpost from './Editpost';
import { useParams } from 'react-router';
import api from '../../../../baseurl'

const Edit = () => {

    const params = useParams();

    useEffect(() => {
        viewPostId(params.id);
    }, []);

    const [ispostId, setpostId] = useState([]);

    const viewPostId = (ids) => {
        axios.post(`${api.baseURL}/v1/admin/blog/edit`, {
            id: ids
        }).then(res => {
            setpostId(res.data)
            console.log("res: ", res.data)
        }).catch((error) => {

        })
    }

    return (
        <>
            {ispostId.length > 0 ? <>
                <Editpost postList={ispostId} editPostID={params.id} />
            </> : null}

        </>
    )
}
export default Edit