import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SyncLoader from "react-spinners/SyncLoader";

import api from '../../../baseurl';
import css from '../clients/style.module.css'
import authHeader from '../../../components/setAuthToken';

function PaymentList() {

    const [loading, setLoading] = useState(true)
    const [paymentList, setPaymentList] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        setErrorText("");
        setPaymentList("");

        axios.get(`${api.baseURL}/v1/admin/paymentlist`, {
            headers: authHeader()
        }).then((response) => {
            if (response.data.message) {
                setErrorText(response.data.message);
            } else {
                setPaymentList(response.data.reverse())
            }
            setLoading(false)
        }).catch((error) => {
            setErrorText(error.message);
            setLoading(false)
        })
    }, [])

    return (
        loading ? <SyncLoader /> :
            <div>
                <div className={css.users}>
                    {errorText && <span >{errorText}</span>}
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Date</th>
                                <th>Үнийн дүн</th>
                                <th>Payment Status</th>
                                <th>Payment Wallet</th>
                                <th>sender_invoice_no</th>
                                <th>invoice_id</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                paymentList.length ?
                                    paymentList.map((val, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{val.date}</td>
                                                <td>{val.data.rows[0].payment_amount}</td>
                                                <td>{val.data.rows[0].payment_status}</td>
                                                <td>{val.data.rows[0].payment_wallet}</td>
                                                <td>{val.sender_invoice_no}</td>
                                                <td>{val.invoice_id}</td>
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={7}><h3>Үр дүн олдсонгүй</h3></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
    );
}

export default PaymentList;
