import * as XLSX from 'xlsx';
import moment from 'moment';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SyncLoader from "react-spinners/SyncLoader";

function UploadCSV() {
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("")

    const [data, setData] = useState([]);
    const [dansDetails, setDansDetails] = useState([]);
    const [selectedDans, setSelectedDans] = useState("");

    let bank = [{}];

    // handle file upload
    const handleFileUpload = (e, setData1, setloading1) => {
        e.preventDefault();
        setloading1(true)
        bank = [];
        const bankName = e.target.getAttribute("name");

        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {

            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

            setData1(data)
        };
        setloading1(false)
        reader.readAsBinaryString(file);
    }

    // Дансны нэрийг нэмнэ
    const submit = () => {
        console.log("data len: ", data.length)
        console.log("data: ", data)
        // setSuccessText("")
        // setErrorText("")
        // setLoading(true)
        // if (data.length > 0) {
        //     axios.post(`${api.baseURL}/v1/add-dansnii-huulga`, {
        //         data: data,
        //         bankCode: selectedDans.split("-")[0],
        //         dansniiDugaar: selectedDans.split("-")[1],
        //         currency: selectedDans.split("-")[2],
        //     }, {
        //         headers: { ...authHeader() }
        //     }).then((response) => {
        //         if (response.data.affectedRows > 0) {
        //             setSuccessText(`Хуулга амжилттай хадгалагдлаа. ${response.data.message}`)
        //             setData([])
        //             setLoading(false);
        //         } else {
        //             setErrorText(`${response.data.message}`)
        //             setLoading(false);
        //         }
        //     }).catch((error) => {
        //         setErrorText(`Сервертэй холбогдоход алдаа гарлаа. ${error.message}`)
        //         setLoading(false);
        //     })
        // } else {
        //     setErrorText(`Хуулга хоосон байна`)
        //     setLoading(false);
        // }

    }

    return (
        <div className='section'>
            <h3 className='title'>Дансны хуулга оруулах {data.length}</h3>
            {errorText && (<article className="message is-warning"><div className="message-body">{errorText}</div></article>)}
            {successText && (<article className="message is-success"><div className="message-body">{successText}</div></article>)}

            <div >
                <div>
                    <input type="file" name="csv" onChange={(e) => { handleFileUpload(e, setData, setLoading) }} />
                </div>
                <SyncLoader loading={loading} />
                <div>
                    <button className="" onClick={submit}>Хуулгыг хадгалах</button>
                </div>
            </div>
        </div >
    )
}
export default UploadCSV;