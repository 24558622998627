import React from 'react';
import { useNavigate } from "react-router-dom";
import css from './style.module.css'
import axios from 'axios';
import api from '../../baseurl';

import { NavLink } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';

function Header() {
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";
    const navigate = useNavigate()
    const message = {
        mn: {
            login: "Нэвтрэх/Бүртгүүлэх",
            logout: "Гарах",
            home: "Нүүр"
        },
        en: {
            login: "Login/Sign Up",
            logout: "Log out",
            home: "Home"
        }
    }

    function logout() {
        axios.get(`${api.baseURL}/logout`)
        localStorage.removeItem("loggedIn")
        navigate("/")
    }

    function changeLocale(event) {
        event.preventDefault();
        localStorage.setItem("locale", event.target.value)
        navigate(0)
    }

    const login = localStorage.getItem("loggedIn")
    return (
        <header className={css.header}>
            <div className={css.headerContent}>
                <NavLink className={css.title} to="/">CENTROPOLIS LLC</NavLink>
                {/* <span className={css.title}>Утас: <a href='tel:77367799'>7736-7799</a></span> */}
                <div className={css.menus}>
                    <NavLink className={css.menuItem} to="/" onClick={() => navigate("/")}>{message[locale].home}</NavLink>
                    {// <NavLink className={css.menuItem} to="/price">Үнийн санал</NavLink>
                    }
                    {
                        login ?
                            <>
                                <NavLink className={css.menuItem} to="/dashboard" ><BiUserCircle size={28} /></NavLink>
                                <NavLink className={css.menuItem} to="/" onClick={() => { logout() }} ><FiLogOut size={20} /> {message[locale].logout}</NavLink>
                            </> : <NavLink className={css.menuItem} to="/login">{message[locale].login}</NavLink>
                    }
                    <div className={css.menuItem}>
                        <select style={{ border: "none", borderRadius: "4px", padding: "2px" }} defaultValue={locale} onChange={(e) => changeLocale(e)}>
                            <option value="mn">MN</option>
                            <option value="en">EN</option>
                        </select>
                    </div>
                </div>
            </div>
        </header >
    );
}

export default Header;
