import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';

import api from '../../../../baseurl'


function GetBlogs() {
    let navigate = useNavigate();
    const [blogs, setBlogs] = useState([])
    const [loading, setLoading] = useState(true)
    const [errorText, setErrorText] = useState(null)

    useEffect(() => {
        axios.get(`${api.baseURL}/v1/admin/blogs`).then(res => {
            console.log("blogs: ", res.data)
            if (res.data.message) {

            } else {
                setBlogs(res.data)
            }
            setLoading(false)
        }).catch((error) => {
            setErrorText("Системийн алдаа хэсэг хугацааны дараа дахин хайна уу.");
            setLoading(false)
        })
    }, [])

    return (
        <>
            <div className="container">
                <div className="row">
                    {
                        blogs.length ?
                            blogs.map((val, index) => {
                                return (
                                    <div key={index}>
                                        <h3>{val.title}</h3>
                                        <div className="post__description" dangerouslySetInnerHTML={{ __html: val.description }} />
                                        <Link to={`/Edit/${val.id}`} className="btn btn__theme"> Edit </Link>
                                    </div>
                                )
                            })
                            : <div>Мэдээлэл олдсонгүй</div>
                    }
                </div>
            </div>
        </>
    )
}

export default GetBlogs;