import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import axios from 'axios';

import Header from './components/header';
import Home from './pages/home';
import Footer from './components/footer';
import Login from './pages/login';
import User from './pages/user';
import ForgotPassword from './pages/user/forgotPassword';
import ResetPassword from './pages/user/resetPassword';
import CarDetails from './pages/carDetails/oneCar';
import LoginAdmin from './pages/admin/login';
import AdminPanel from './pages/admin';
import AddBlog from './pages/admin/blog/add';
import GetBlogs from './pages/admin/blog/get';
import Edit from './pages/admin/blog/edit';
import Blog1 from './pages/admin/blog/blogs/blog1';
import Blog2 from './pages/admin/blog/blogs/blog2';
import UploadCSV from './components/exceltojson';

function App() {
  axios.defaults.withCredentials = true;

  useEffect(() => {
    const locale = localStorage.getItem("locale");
    !locale && localStorage.setItem("locale", "mn");
  }, [])

  return (
    <Router>
      <div className="App">
        <Header />
        <div style={{ height: '50px', maxWidth: '1100px', align: 'center', margin: 'auto' }}></div>
        <div className='container'>
          <div className='content'>
            <Routes>
              <Route exact path="/*" element={<Home />} />
              <Route exact path="/blog/chassisnumber" element={<Blog1 />} />
              <Route exact path="/blog/auctionsheet" element={<Blog2 />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/dashboard" element={<User />} />
              <Route exact path="/forgot-password" element={<ForgotPassword />} />
              <Route exact path="/reset-password/:token" element={<ResetPassword />} />
              <Route exact path="/stats/auction/:id/:modelNumber/:type" element={<CarDetails />} />
              <Route exact path="/system/admin" element={<LoginAdmin />} />
              <Route exact path="/ec9ae912/panel" element={<AdminPanel />} />
              <Route exact path="/system/admin/add-blog" element={<AddBlog />} />
              <Route exact path="/system/admin/blogs" element={<GetBlogs />} />
              <Route exact path="/system/admin/blogs/:id" element={<Edit />} />
              <Route exact path="/upload" element={<UploadCSV />} />
              <Route exact path="*" />
            </Routes>
          </div>
        </div>
        <Footer />
      </div >
    </Router>
  );
}

export default App;
