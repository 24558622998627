import React from 'react';
import css from './style.module.css';

function AuctionDescTableEn() {

    return (
        <div className={css.tables}>
            <h3 style={{ width: '100%' }}>Description of the rating on the auction page</h3>
            <div className={css.tablesCol}>
                <table className={css.auction}>
                    <thead>
                        <tr>
                            <th colSpan={2}>External skin lesions</th>
                        </tr>
                        <tr>
                            <th>Evaluation</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A1</td><td>Small scratches barely visible to the eye</td>
                        </tr>
                        <tr>
                            <td>A2</td><td>Minor scratches</td>
                        </tr>
                        <tr>
                            <td>A3</td><td>Large line</td>
                        </tr>
                        <tr>
                            <td>U(B)1</td><td>A small dimple barely visible to the eye</td>
                        </tr>
                        <tr>
                            <td>U(B)2</td><td>Minor dent</td>
                        </tr>
                        <tr>
                            <td>U(B)3</td><td>Large dent</td>
                        </tr>
                        <tr>
                            <td>AU(AB)1</td><td>Dimple with small scratches</td>
                        </tr>
                        <tr>
                            <td>AU(AB)2</td><td>Moderate scratch dent</td>
                        </tr>
                        <tr>
                            <td>AU(AB)3</td><td>Dimple with large scratches</td>
                        </tr>
                        <tr>
                            <td>W1</td><td>It was painted with a little correction that was barely visible to the eye</td>
                        </tr>
                        <tr>
                            <td>W2</td><td>A little retouching and painting</td>
                        </tr>
                        <tr>
                            <td>W3</td><td>Heavy retouching</td>
                        </tr>
                        <tr>
                            <td>P1</td><td>Balding spots, small</td>
                        </tr>
                        <tr>
                            <td>P2</td><td>Balding spots, medium</td>
                        </tr>
                        <tr>
                            <td>P3</td><td>Bald patches, large</td>
                        </tr>
                        <tr>
                            <td>X</td><td>Need to be replaced</td>
                        </tr>
                        <tr>
                            <td>XX</td><td>Replaced</td>
                        </tr>
                        <tr>
                            <td>S</td><td>Rusty</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className={css.tablesCol}>
                <table className={css.auction}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Overall car rating</th>
                        </tr>
                        <tr>
                            <th>Evaluation</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>S</td><td>New Car</td>
                        </tr>
                        <tr>
                            <td>5</td><td>Newer</td>
                        </tr>
                        <tr>
                            <td>4,5</td><td>Very good car. There are no scratches or dents</td>
                        </tr>
                        <tr>
                            <td>4</td><td>Good car. A few scratches</td>
                        </tr>
                        <tr>
                            <td>3,5</td><td>Car with scratches and blemishes. But normal</td>
                        </tr>
                        <tr>
                            <td>3</td><td>The paint is peeling and has big scratches</td>
                        </tr>
                        <tr>
                            <td>RA</td><td>Minor accident, repaired to standard</td>
                        </tr>
                        <tr>
                            <td>R/A/0</td><td>Crash, some parts replaced or fixed</td>
                        </tr>
                        <tr>
                            <td>***</td><td>Crash damaged and not repaired. Damaged engine</td>
                        </tr>
                    </tbody>
                </table>

                <table style={{ marginTop: '10px' }} className={css.auction}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Salon rating</th>
                        </tr>
                        <tr>
                            <th>Evaluation</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A</td><td>Salon new</td>
                        </tr>
                        <tr>
                            <td>B</td><td>The salon is clean. Very rare</td>
                        </tr>
                        <tr>
                            <td>C</td><td>Salon needs to be cleaned. No injury</td>
                        </tr>
                        <tr>
                            <td>D</td><td>Salon needs to be cleaned. Minor damage</td>
                        </tr>
                        <tr>
                            <td>E</td><td>Salon is bad and damaged. Needs editing</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AuctionDescTableEn;
