import React from 'react';
import css from './style.module.css'

function Footer() {
    const year = new Date().getFullYear();
    
    return (
        <footer className={css.footer}>
            <div className={css.footerContent}>
                <div className={css.menus}>
                    <span>Copyright © {year} CENTROPOLIS LLC</span>
                </div>
                <div className={css.menus}>
                    <span>Phone: <a href='tel:+97699078299'>+976 99078299</a></span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
