import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SyncLoader from "react-spinners/SyncLoader";

import api from '../../../baseurl';
import css from '../clients/style.module.css';
import authHeader from '../../../components/setAuthToken';

function ReportHistory() {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        setErrorText("");
        setData("");

        axios.get(`${api.baseURL}/v1/admin/reports-history`, {
            headers: authHeader()
        }).then((response) => {
            if (response.data.status === true) {
                setData(response.data.data)
            } else {
                setErrorText(response.data.message);
            }
        }).catch((error) => {
            setErrorText(error.message);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        loading ? <SyncLoader /> :
            <div>
                <div className={css.users}>
                    {errorText && <span >{errorText}</span>}
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Time</th>
                                <th>Chassis</th>
                                <th>Url</th>
                                <th>type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.length ?
                                    data.map((val, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{val.chassis._text}</td>
                                                <td>{val.time._text}</td>
                                                <td><a href={`https://jpcenter.ru/${val.report._text.split("=")[1]}`} rel="noreferrer" target="_blank">{val.report._text}</a></td>
                                                <td>{val.type._text}</td>
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={4}><h3>Үр дүн олдсонгүй</h3></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
    );
}

export default ReportHistory;
