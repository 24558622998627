import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import SyncLoader from "react-spinners/SyncLoader";
import NumberFormat from 'react-number-format';

import css from './style.module.css'
import api from '../../baseurl';
import Qpay from '../qpay';
import Dans from '../dans';
import CheckPaymentAuto from './checkPayment';
import LoginAndSignUp from '../login';
import Popup from '../../components/popup';
import findCar from '../../report-found.png';
import AuctionDescTable from './auctionDescTable';
import AuctionDescTableEn from './auctionDescTableEn';
import auctionImgLast from '../../images/auction_tailbarLast.png';


function CarDetails() {
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";
    const params = useParams();
    const [coupon, setCoupon] = useState("")
    const [phone, setPhone] = useState("")

    const [loading, setLoading] = useState(true)
    const [errorText, setErrorText] = useState("")
    const [errorTextCoupon, setErrorTextCoupon] = useState("")
    const [dans, setDans] = useState(false)

    const [resultAuction, setResultAuction] = useState({});

    const [clickedImg, setClickedImg] = useState("")
    const [clickedImgAlt, setClickedImgAlt] = useState("")
    const [showImg, setShowImg] = useState(false)

    const [selectedPrice, setSelectedPrice] = useState(5000)
    const [qpayInvoice, setQpayInvoice] = useState("")
    const [loadingQpay, setLoadingQpay] = useState(false)

    const [loadingPayment, setLoadingPayment] = useState(false)
    const [errorPayment, setErrorPayment] = useState("")

    const [loginCheck, setLoginCheck] = useState(false)

    const message = {
        mn: {
            title: "Тээврийн хэрэгслийн мэдээлэл",
            text1: "Үйлдвэрлэсэн он",
            text2: "Арлын дугаар",
            text3: "Моторын багтаамж",
            text4: "Үнэлгээ",
            text5: "Явсан км",
            text6: "Өнгө",
            photos: "Зураг",
            auctionImage: "Төлбөр төлсний дараа үнэлгээний хуудасны зураг харагдана.",
            auctionDownload: "Таныг auction-ий зургаа татаж авч хадгалахыг зөвлөж байна.",
            requirePayment: "Төлбөр төлсний дараа харагдана",
            promoCode: "Промо код оруулах",
            promoPhone: "Таны утасны дугаар",
            promoSubmit: "Шалгах",
            error: "Системийн алдаа хэсэг хугацааны дараа дахин хайна уу.",
        },
        en: {
            title: "Vehicle Information",
            text1: "Year",
            text2: "Chassis Number",
            text3: "Engine Size",
            text4: "Scores",
            text5: "Mileage, km",
            text6: "Color",
            photos: "Photos",
            auctionImage: "An image of the evaluation page is displayed after payment.",
            auctionDownload: "We recommend that you download and save the auction images.",
            requirePayment: "Shown after payment",
            promoCode: "Enter promo code",
            promoPhone: "Your phone number",
            promoSubmit: "Submit",
            error: "System error. Please try again after a while.",
        }
    }


    useEffect(() => {
        setResultAuction("");
        axios.post(`${api.baseURL}/v1/get-auction/${params.id}`, {
            id: params.id,
            uuid: localStorage.getItem("uuid"),
            type: params.type,
            modelNumber: params.modelNumber,
        }).then((response) => {
            if (response.data.status === true) {
                setResultAuction(response.data.data)
                setSelectedPrice(parseInt(response.data.data.price))
            } else {
                setErrorText(locale === 'mn' ? response.data.message : response.data.messageEn);
            }
            setLoading(false)
        }).catch((error) => {
            setErrorText(message[locale].error)
            setLoading(false)
        })
    }, [params])

    function createInvoice() {
        setQpayInvoice("")
        setErrorText("")
        setLoadingQpay(true)

        fetch('https://api.ipify.org/?format=json').then(results => results.json()).then((data) => {
            axios.post(`${api.baseURL}/v1/qpay/createInvoice`, {
                modelNumber: `${params.modelNumber}`,
                clientIp: data.ip,
                price: selectedPrice,
                count: 1,
                uuid: localStorage.getItem("uuid"),
                id: params.id,
                type: params.type,
            }).then((response) => {
                if (response.data.message) {
                    if (response.data.uuid) {
                        localStorage.setItem("uuid", response.data.uuid)
                    }
                    setErrorText(response.data.message)
                } else if (response.data.data) {
                    setQpayInvoice(response.data.data)
                    localStorage.setItem("uuid", response.data.uuid)
                } else {
                    setQpayInvoice(response.data)
                }
            }).catch((error) => {
                setErrorText(message[locale].error)
            }).finally(() => {
                setLoadingQpay(false)
            })
        }).catch((error) => {
            setErrorText("Дахин оролдоно уу")
        })
    }

    // Харах эрхээ ашиглахад хэрэглэгдэх функц
    // function submitFindModel() {
    //     setErrorText("")
    //     axios.put(`${api.baseURL}/update-model-number`, {
    //         uuid: localStorage.getItem("uuid"),
    //         id: params.id,
    //     }).then((response) => {
    //         if (response.data.status) {
    //         } else {
    //             setErrorText(message[locale].error)
    //         }
    //     }).catch((error) => {
    //         setErrorText(message[locale].error)
    //     })
    // }

    function couponSubmit() {
        setErrorTextCoupon("")
        if (coupon.length > 2) {
            if (phone.length > 7) {
                axios.post(`${api.baseURL}/v1/coupon/`, {
                    coupon: coupon,
                    id: params.id,
                    phone: phone,
                    modelNumber: params.modelNumber,
                    type: params.type,
                }).then((response) => {
                    if (response.data.status === true) {
                        setResultAuction(response.data.data)
                    } else {
                        setErrorTextCoupon(response.data.message)
                    }
                }).catch((error) => {
                    setErrorTextCoupon(message[locale].error)
                })
            } else {
                setErrorTextCoupon(locale === "mn" ? "Утасны дугаараа зөв оруулна уу" : "Wrong Phone number")
            }
        } else {
            setErrorTextCoupon(locale === "mn" ? "Промо кодоо оруулна уу" : "Pls enter Pormo code")
        }
    }

    function checkPayment() {
        setErrorPayment("")
        setLoadingPayment(true)
        axios.post(`${api.baseURL}/v1/qpay/checkPayment/${qpayInvoice.invoice_id}`, {
            invoice_id: qpayInvoice.invoice_id,
            type: params.type,
        }).then((response) => {
            if (response.data.status === true) {
                setResultAuction(response.data.data)
            } else {
                setErrorPayment(response.data.message)
                // setErrorPayment(locale === 'mn' ? `Төлбөр төлөгдөөгүй байна` : `Payment has not been made`)
            }
        }).catch((error) => {
            setErrorPayment(error.message)
        }).finally(() => {
            setLoadingPayment(false)
        })
    }

    return (
        loading ? <SyncLoader /> :
            resultAuction &&
            <>
                <div className={css.carDetails} id="auction">
                    <h3>{message[locale].title}</h3>
                    <img style={{ width: '100px', margin: 'auto' }} src={findCar} alt='' />
                    <div>
                        <div className={css.carInfoTitle}>{`${resultAuction.maker} ${resultAuction.model} ${resultAuction.grade}`}</div>
                        <div>
                            <table className={css.table}>
                                <tbody>
                                    <tr>
                                        <td>{message[locale].text1}</td>
                                        <td>{resultAuction.year}</td>
                                        <td>{message[locale].text4}</td>
                                        <td>{resultAuction.score === undefined ? message[locale].requirePayment : resultAuction.score}</td>
                                    </tr>
                                    <tr>
                                        <td>{message[locale].text2}</td>
                                        <td><img width={180} height={60} src={`${resultAuction.crop_auction_id}`} alt='' /></td>
                                        <td>{message[locale].text5}</td>
                                        <td>{resultAuction.mileage === undefined ? message[locale].requirePayment : resultAuction.mileage}</td>
                                    </tr>
                                    <tr>
                                        <td>{message[locale].text3}</td>
                                        <td>{resultAuction.engineSize}</td>
                                        <td>{message[locale].text6}</td>
                                        <td>{resultAuction.color}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <div className={css.carInfoTitle}>{message[locale].photos}</div>
                        <div className={css.imgContain}>
                            {
                                resultAuction.auction_images_id === undefined ? null :
                                    <div className={css.img} >
                                        <img src={`${resultAuction.auction_images_id}`} alt="Үнэлгээний хуудасны зураг" onClick={(e) => { setClickedImg(e.target.src); setClickedImgAlt(e.target.alt); setShowImg(true) }} />
                                    </div>
                            }
                            {
                                resultAuction.car_images_id === undefined ? null :
                                    <div className={css.img} >
                                        <img src={`${resultAuction.car_images_id}`} alt="Автомашины зураг" onClick={(e) => { setClickedImg(e.target.src); setClickedImgAlt(e.target.alt); setShowImg(true) }} />
                                    </div>
                            }
                            <div className={css.img} >
                                <img src={`${auctionImgLast}`} alt="Дуудлага худалдааны хуудасны тайлбар" onClick={(e) => { setClickedImg(e.target.src); setClickedImgAlt(e.target.alt); setShowImg(true) }} />
                            </div>
                        </div>
                        {
                            resultAuction.auction_images_id === undefined ?
                                <span style={{ color: '#ff7043', fontStyle: 'italic' }}>{message[locale].auctionImage}</span>
                                : <span style={{ color: 'green', fontStyle: 'italic' }}>{message[locale].auctionDownload}</span>
                        }
                    </div>
                    {
                        <div className={css.lotButtons}>
                            {
                                resultAuction.auction_images_id === undefined ?
                                    <div>
                                        <input type="text" placeholder={message[locale].promoCode} onChange={(e) => { setCoupon(e.target.value) }} />
                                        <input type="text" placeholder={message[locale].promoPhone} onChange={(e) => { setPhone(e.target.value) }} />
                                        <button onClick={couponSubmit}>{message[locale].promoSubmit}</button>
                                    </div> : null
                            }
                            {errorTextCoupon && <span style={{ color: 'red', width: "100%", fontSize: "12px", textAlign: "left", marginLeft: "10px", paddingBottom: '10px', display: "block" }}>{errorTextCoupon}</span>}
                            {
                                // type === 0 && !checkPaymentResult && <button className={css.lot} onClick={() => { submitFindModel() }}>Эрхээ ашиглаж харах</button>
                            }
                        </div>
                    }
                    {
                        resultAuction.auction_images_id === undefined ?
                            <div className={css.price} id='paymentDetails'>
                                <div className={css.carInfoTitle}>ТӨЛБӨРИЙН МЭДЭЭЛЭЛ</div>
                                <div><strong>ҮНЭЛГЭЭ ШАЛГАХ ҮНЭ: <NumberFormat displayType={'text'} thousandSeparator={true} suffix='₮' decimalScale={2} value={selectedPrice} /></strong></div>
                                <div>
                                    <button className={css.payment} disabled={qpayInvoice ? 'disabled' : ''} onClick={() => { createInvoice(); setDans(false); setErrorPayment("") }}>QPay-ээр төлөх</button>
                                    <button className={css.payment} disabled={dans ? 'disabled' : ''} onClick={() => { setDans(true); setQpayInvoice(""); setErrorText("") }}>Дансаар төлөх</button>
                                </div>
                                {
                                    loadingQpay ? <SyncLoader loading={loadingQpay} size={12} /> :
                                        qpayInvoice &&
                                        <>
                                            <CheckPaymentAuto checkPayment={checkPayment} />
                                            <Qpay data={qpayInvoice} price={selectedPrice} checkPayment={checkPayment} errorText={errorPayment} loading={loadingPayment} />
                                        </>
                                }
                                {errorText && <span style={{ color: 'red', padding: '10px' }}>{errorText}</span>}
                                {dans && <Dans />}
                            </div> : null
                    }
                    {
                        // !localStorage.getItem("loggedIn") && <span className={css.loginReq}>Та бүртгүүлснээр худалдаж авсан арлын дугаарын эрхээ цаашид Он дуустал үнэгүй харах боломжтой. <mark onClick={() => setLoginCheck(true)}>Нэвтрэх / Бүртгүүлэх</mark></span>
                    }
                    {
                        locale === "mn" ? <AuctionDescTable /> : <AuctionDescTableEn />
                    }
                </div>

                {/* Зураг дээр дарахад тухайн зургийг POPUP-аар харуулна */}
                <Popup show={showImg ? true : false} setShow={setShowImg} >
                    <span>{clickedImgAlt}</span>
                    <img style={{ width: "100%" }} src={clickedImg} alt="" />
                </Popup>

                {/* Төлбөр төлсөн хэрэглэгчийн нэвтрэх цонхыг POPUP-аар харуулна */}
                <Popup show={loginCheck ? true : false} setShow={setLoginCheck} >
                    <LoginAndSignUp type="paid" uuid={localStorage.getItem("uuid")} />
                </Popup>
            </>
    );
}

export default CarDetails;
