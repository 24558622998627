import React, { useState } from 'react';
import axios from 'axios';
import api from '../../../baseurl'
import css from '../style.module.css'

function ForgotPassword() {
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";
    const [email, setEmail] = useState("");
    const [errorText, setErrorText] = useState(null);

    const submit = () => {

        if (email) {
            axios.post(`${api.baseURL}/forgot-password`, {
                email: email,
            }).then((response) => {
                if (response.data.message) {
                    setErrorText(response.data.message)
                }
            }).catch((error) => {
                setErrorText(error.message)
            })
        } else {
            setErrorText("Имэйл хаягаа оруулна уу")
        }
    }
    const message = {
        mn: {
            text: "Мэйл хаягаа оруулна уу...",
            submit: "Илгээх"
        },
        en: {
            text: "Enter your e-mail",
            submit: "Submit"
        }
    }

    return (
        <div className={css.formContent}>
            <div>
                {errorText && (<article className="message is-warning"><div className="message-body">{errorText}</div></article>)}
                <div>
                    <label>{message[locale].text}</label>
                    <input type="email" onChange={(e) => {
                        setEmail(e.target.value);
                    }} />
                </div>
                <div>
                    <input type="submit" name="submit" value={message[locale].submit} onClick={submit} />
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;