import React from 'react';
import css from './style.module.css'

function Dans() {

    return (
        <div>
            <div style={{ padding: '10px' }}>
                <span style={{ fontWeight: '500' }}>ДАНСААР ШИЛЖҮҮЛЭХ</span>
            </div>
            <div>
                <table className={css.paymentDans} style={{ maxWidth: '400px', margin: 'auto' }}>
                    <tbody>
                        <tr>
                            <td>Банкны нэр:</td>
                            <td>Хаан банк</td>
                        </tr>
                        <tr>
                            <td>Дансны нэр:</td>
                            <td>С.Ууганбаяр</td>
                        </tr>
                        <tr>
                            <td>Дансны дугаар:</td>
                            <td>516 719 3427</td>
                        </tr>
                        <tr>
                            <td>Гүйлгээний утга:</td>
                            <td>Утасны дугаар, арлын дугаар</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ color: 'red', fontSize: '13px'}}>Та төлбөрөө шилжүүлсний дараа ажлын цагаар <a href='tel:99078299'><mark>99078299</mark></a> дугаарт залгаж мэдэгдэнэ үү.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Dans;