import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SyncLoader from "react-spinners/SyncLoader";
import moment from 'moment'

import api from '../../../baseurl';
import css from '../clients/style.module.css';
import authHeader from '../../../components/setAuthToken';

function SearchAuctionEmpty() {

    const [loading, setLoading] = useState(true)
    const [auctionEmpty, setAuctionEmpty] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        setErrorText("");
        setAuctionEmpty("");

        axios.get(`${api.baseURL}/v1/admin/search-auction-empty`, {
            headers: authHeader()
        }).then((response) => {
            if (response.data.message) {
                setErrorText(response.data.message);
            } else {
                setAuctionEmpty(response.data)
            }
            setLoading(false)
        }).catch((error) => {
            setErrorText(error.message);
            setLoading(false)
        })
    }, [])

    return (
        loading ? <SyncLoader /> :
            <div>
                <div className={css.users}>
                    {errorText && <span >{errorText}</span>}
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Auction</th>
                                <th>Count</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                auctionEmpty.length ?
                                    auctionEmpty.map((val, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{val.auction}</td>
                                                <td>{val.auctionCount}</td>
                                                <td>{moment(val.date).format('YYYY-MM-DD')}</td>
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={4}><h3>Үр дүн олдсонгүй</h3></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
    );
}

export default SearchAuctionEmpty;
