import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import api from '../../../../baseurl'




function AddBlog() {
    let navigate = useNavigate();
    const [blogs, setBlogs] = useState([])
    const [errorText, setErrorText] = useState(null)

    const [userInfo, setuserInfo] = useState({
        title: '',
    });

    const onChangeValue = (e) => {
        setuserInfo({
            ...userInfo,
            [e.target.name]: e.target.value
        });
    }

    let editorState = EditorState.createEmpty();
    const [description, setDescription] = useState(editorState);

    const onEditorStateChange = (editorState) => {
        setDescription(editorState);
    }

    const [isError, setError] = useState(null);

    const addDetails = () => {
        axios.post(`${api.baseURL}/v1/admin/blog/add`, {
            title: userInfo.title,
            description: userInfo.description.value
        }).then(res => {
            console.log("resAdd: ", res.data)
            if (res.data.affectedRows == 1) {
                setError("Хадгалагдлаа")
            } else {
                setError(res.data.message)
            }
        })
    }

    const selectDetails = async (event) => {
        try {
            event.preventDefault();
            event.persist();
            axios.get(`${api.baseURL}/v1/admin/blog/get`).then(res => {
                console.log("blogs: ", res.data)
                if (res.data.message) {
                    setError(res.data.message)
                } else {
                    setBlogs(res.data)
                }
            })
        } catch (error) { throw error; }
    }

    return (
        <div className="row">
            <h3 className="myaccount-content">Шинэ мэдээлэл нэмэх</h3>
            <div className="form-row">
                <div className="form-group col-md-12">
                    <input style={{ width: 'calc(100% - 20px)', height: '30px', fontSize: '14px', lineHeight: '100%', marginBottom: '30px', paddingLeft: '10px' }} type="text" name="title" value={userInfo.title} onChange={onChangeValue} className="form-control" placeholder="Гарчиг оруулах" required />
                </div>
                <div className="form-group col-md-12 editor">
                    <Editor
                        editorState={description}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                    />
                    <textarea style={{ display: 'none' }} disabled ref={(val) => userInfo.description = val} value={draftToHtml(convertToRaw(description.getCurrentContent()))} />
                </div>
                {isError !== null && <div className="errors"> {isError} </div>}
                <div className="form-group col-sm-12 text-right ">
                    <button type="submit" onClick={addDetails} className="btn btn__theme">Оруулах</button>
                </div>
            </div>
        </div>
    )
}

export default AddBlog;