import React, { useState } from 'react';
import axios from 'axios';
import SyncLoader from "react-spinners/SyncLoader";

import api from '../../../baseurl';
import css from './style.module.css'

function EditForm(props) {

    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(false);
    const [errorText, setErrorText] = useState("");

    const [carId, setCarId] = useState("");
    const [count, setCount] = useState("");

    function updateClient() {
        setLoading(true)
        setStatus(false)
        setErrorText("")
        if (count && carId && props.id) {
            axios.post(`${api.baseURL}/v1/admin/update-client`, {
                id: props.id,
                count: count,
                carId: carId,
            }).then((response) => {
                if (response.data.message) {
                    if (response.data.message === "Амжилттай шинэчлэгдлээ") {
                        setStatus(true)
                    }
                    setErrorText(response.data.message);
                } else {
                    setErrorText(response.data);
                }
                setLoading(false)
            }).catch((error) => {
                setErrorText(error.message);
                setLoading(false)
            })
        } else {
            setErrorText("Талбаруудыг гүйцэд бөглөнө үү.")
            setLoading(false)
        }
    }

    return (

        <div>
            <div className={css.desc}>{props.id} ID-тай хэрэглэгчийн мэдээллийг шинэчлэх</div>
            <div className={css.editForm}>
                {loading ? <SyncLoader /> : errorText && (<article className="message is-warning"><div className="message-body">{errorText}</div></article>)}
                <div>
                    <label>Auction code</label>
                    <input type="text" onChange={(e) => { setCarId(e.target.value.trim()) }} />
                </div>
                <div>
                    <label>Auction тоо ширхэг</label>
                    <input type="number" onChange={(e) => { setCount(e.target.value.trim()) }} />
                </div>
                <div>
                    <button disabled={status ? "disabled" : ''} onClick={updateClient} >Шинэчлэх</button>
                </div>
            </div>
        </div>
    );
}

export default EditForm;
