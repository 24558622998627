import React, { useState, useEffect } from 'react';
import css from './style.module.css'
import aral1 from '../../../../images/aralno1.png'
import aral2 from '../../../../images/aralno2.png'
import aralBairshil from '../../../../images/aralbairshil.png'


function Blog1() {

    return (
        <div className={css.blog}>
            <div className={css.blogTitle}>
                <h2>Автомашины арлын дугаар буюу явах эд ангийн дугаар гэж юу вэ?</h2>
            </div>
            <div className={css.blogContent}>
                <p>VIN буюу явах эд ангийн дугаар нь үйлдвэрлэгчээс таны машинд олгосон машиныг таних давтагдашгүй дугаар юм. Энэхүү онцгой дугаар нь тус машины үйлдвэр, загварыг илтгэсэн тэмдэглэгээнүүд болон хувийн дугаараас бүрдсэн 17 хүртэлх оронтой байж болно.</p>
                <p><strong>Жишээлбэл:</strong></p>

                <div className={css.imgs}>
                    <img src={aral1} alt="" />
                    <img src={aral2} alt="" />
                </div>

                <p><strong>Өөрийн машины арлын дугаараа та яаж мэдэх вэ?</strong></p>
                <p>Энэхүү дугаар нь таны машины улсын бүртгэлийн гэрчилгээнд байхаас гадна автомашины хэд хэдэн эд ангийн хэсэгт байрладаг.</p>
                <p><strong>Арлын дугаарын хамгийн түгээмэл байршлууд:</strong></p>


                <ul>
                    <ol>
                        <p>
                            <li>Жолооч талын салхины шилний доод буланд</li>
                            <li>Жолооч талын хаалга болон харгалзах их биеийн хэсэгт</li>
                            <li>Багажын баганад гэх мэт байршлуудад үйлдвэрээсээ хамааран өөр өөр байдаг.</li>
                        </p>
                    </ol>
                </ul>

                <div className={css.imgs}>
                    <img src={aralBairshil} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Blog1;