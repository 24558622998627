import React, { useEffect, useState } from 'react';
import axios from 'axios';
import api from '../../baseurl';
import css from './style.module.css'
import UserInfo from './components/userInfo';
import ChangePassword from './components/changePassword';

import SyncLoader from "react-spinners/SyncLoader";


function User() {

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [values, setValues] = useState([])
    const [errorText, setErrorText] = useState(null);

    const [selectedTab, setSelectedTab] = useState({
        info: true,
        logout: false,
        changePass: false
    });

    useEffect(() => {
        setErrorText("")

        axios.get(`${api.baseURL}/check-user-login`).then((response) => {
            if (response.data.loggedIn) {
                localStorage.setItem("loggedIn", true)
                axios.get(`${api.baseURL}/profile`,).then((response) => {
                    if (response.data.message) {
                        setErrorText(response.data.message)
                        setLoading(false);
                    } else {
                        setUser(response.data.user)
                        setValues(response.data.auction)
                        setLoading(false);
                    }
                }).catch((error) => {
                    setErrorText(error.message)
                    setLoading(false);
                })
            } else {
                localStorage.removeItem("loggedIn")
                window.location.href = "/";
            }
        }).catch((error) => {
            setErrorText(error.message)
            setLoading(false);
        })
    }, [])

    return (
        loading ? <SyncLoader loading={loading} size={12} /> :
            <div className={css.user}>
                {/* <Sidebar selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> */}
                <div className={css.content}>
                    {errorText && (<article className="message is-warning"><div className="message-body">{errorText}</div></article>)}
                    {selectedTab.info && <UserInfo user={user} values={values} />}
                    {selectedTab.changePass && <ChangePassword />}
                </div>
            </div>
    )
}

export default User;