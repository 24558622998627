import React, { useState } from 'react';
import css from './style.module.css'
import CarDetails from '../carDetails';
import { AiOutlineSetting } from 'react-icons/ai';
import { BsCurrencyDollar } from 'react-icons/bs';
import { BiDetail } from 'react-icons/bi';
import { IoMdSpeedometer } from 'react-icons/io';
import { IoMdCheckboxOutline } from 'react-icons/io';

import {
    Routes,
    Route,
    useNavigate
} from "react-router-dom";

function Home() {
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState("");
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn";
    const [errorText, setErrorText] = useState("");

    function submitSearch(event) {
        event.preventDefault();
        setErrorText("")
        if (searchValue.includes("-")) {
            const search = searchValue.replaceAll(" ", "").trim();
            navigate(`/chassis_number/${search}`);
        } else { setErrorText(message[locale].errorText) }
        // const search = searchValue.replace(/-/g, "").replace(" ", "").trim();
    }

    const message = {
        mn: {
            title: "Арлын дугаарыг нь оруулаад, бодит найдвартай мэдээллийг аваарай",
            text1: "Япон дахь бодит үнэлгээ",
            text2: "Явсан миль",
            text3: "Зарагдсан үнэ",
            text4: "Эвдрэл гэмтэл, сэв зураас",
            text5: "Дуудлага худалдааны мэдээллийн хуудас/ auction sheet",
            auctionNumber: "АРЛЫН ДУГААР ОРУУЛАХ (Chassis ID-Number )",
            example: "Бэлэн жишээн дээр туршиж үзэх: ZVW41-3086779",
            search: "Хайх",
            errorText: "ZVW41-3086779 форматаар оруулна уу.",
        },
        en: {
            title: "CHECK CAR AUCTION SHEET NOW!",
            text1: "Scores",
            text2: "Mileage",
            text3: "Sold Price",
            text4: "Damage, scratches",
            text5: "Auction sheet",
            auctionNumber: "Enter Chassis number (Chassis ID-Number )",
            example: "View the Sample of Report: ZVW41-3086779",
            search: "Search",
            errorText: "ZVW41-3086779 форматаар оруулна уу.",
        }
    }

    return (
        <div className={css.home}>
            <div className={css.desc}>
                <h3>{message[locale].title}</h3>
                <div className={css.twoCol}>
                    <div className={css.iconList}>
                        <span><IoMdCheckboxOutline color="#455a64" size={20} />{message[locale].text1}</span>
                        <span><IoMdSpeedometer color="#455a64" size={20} />{message[locale].text2}</span>
                        <span><BsCurrencyDollar color="#455a64" size={20} />{message[locale].text3}</span>
                        <span><AiOutlineSetting color="#455a64" size={20} />{message[locale].text4}</span>
                        <span><BiDetail color="#455a64" size={20} />{message[locale].text5}</span>
                    </div>
                </div>
            </div>
            <div className={css.searchContain} id="content">
                <form onSubmit={submitSearch}>
                    {errorText && <span className={css.errorText}>{errorText}</span>}
                    <div className={css.searchField}>
                        <label>{message[locale].auctionNumber}</label>
                        <div id='section1'>
                            <input type='text' placeholder='ZVW41-3086779' value={searchValue.toUpperCase()} onChange={(e) => { setSearchValue(e.target.value) }} />
                        </div>
                        <button onClick={submitSearch}>{message[locale].search}</button>
                    </div>
                    <span className={css.example} onClick={() => { setSearchValue("ZVW41-3086779"); navigate(`/chassis_number/ZVW41-3086779`) }}>{message[locale].example}</span>
                </form>
            </div>
            <Routes>
                <Route exact path="/chassis_number/:modelNumber" element={<CarDetails setSearchValue={setSearchValue} />} />
            </Routes>
        </div >
    );
}

export default Home;
